//Fonts
$general-font: 'Helvetica-Neue', sans-serif;

//Settings
$slider-height: 500px;
$details-video-height: 470px;
$video-thumbnail-border-width: 0.3rem;

//Colors
$general-background: white;
