
%ppgtv-link {
  color: #333;
  text-decoration: underline;
  &:hover {
    color: $brand-color-2;
  }
}

.navigation-buttons {
  float: left;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  a {
    color: #0a0a0a;
    border-radius: 100%;
    height: 110px;
    border: 1px solid #f5f5f5;
    padding-top: 20px;
    width: 110px;
    display: block;
    margin: 0px auto;
    &:hover {
      background: #f5f5f5;
    }
    &.active {
      background: #f5f5f5;
    }
  }
  i {
    display: block;
    width: 35px;
    height: 35px;
    margin: 0px auto;
  }
  p {
    margin-top: 5px;
  }
}

.list-box {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.list-box-header {
  border: none;
  color: $brand-color-2;
  text-align: center;
  font-weight: bold;
  th {
    text-align: center;
  }
  .project-list-title {
    text-align: left;
  }
  .columns {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
}

.list-box-content {
  border: none;
  tr {
    background: none;
    border-bottom: 1px solid #d5d5d5;
    &:nth-child(even) {
      background: none;
    }
  }
  td {
    min-height: 115px;
    max-height: 115px;
    @media #{$medium-only} {
      min-height: 170px;
      max-height: 170px;
    }
    @media #{$small-only} {
      min-height: 200px;
      max-height: 200px;
    }
  }
  h4 {
    font-size: 18px;
    color: $brand-color-2;
  }
  time {
    font-style: italic;
    font-size: 14px;
  }
  .list-box-logo {
    max-width: 50px;
    display: block;
    margin: 0px auto;
  }
  .progress {
    display: block;
    margin: 0px auto;
    background: white;
    padding: 1px;
    border: 1px solid grey;
    border-radius: 2px;
  }
  .columns {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  .content-description {
    border-right: 1px solid #d5d5d5;
  }
  .list-logo {
    border-right: 1px solid #d5d5d5;
    padding: 30px 15px;
    @media #{$small-only} {
      padding: 60px 15px;
    }
  }
  .progress-bar {
    padding: 40px 15px;
    @media #{$small-only} {
      padding: 80px 15px;
    }
    text-align: center;
    font-size: 12px;
    color: grey;
    .state {
      display: block;
      margin-top: 5px;
    }
  }
}

.progress {
  .meter {
    display: block;
    height: 100%;
    float: left;
  }
  .meter.published {
    background: #94C021;
    width: 100%;
  }
  .meter.rejected {
    background: red;
    width: 100%;
  }
  .meter.inRevision {
    background: #F29200;
    width: 50%;
  }
  .meter.received {
    background: #FEDE00;
    width: 30%;
  }
}

//Users Chat

.foro-title {
  float: left;
  color: $brand-color-2;
  border-bottom: 1px solid $brand-color-2;
  margin-bottom: 2px;
  i {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    position: relative;
    top: 4px;
  }
}

.users-top-bar {
  float: left;
  font-size: 14px;
  border-top: 1px solid $brand-color-2;
  border-bottom: 1px solid $brand-color-1;
  .bar {
    padding: 3px;
  }
}

.box-icon {
  width: 125px;
  float: left;
  position: relative;
  top: 2px;
  margin-bottom: 5px;
  min-height: 25px;
  line-height: 22px;
  padding: 0;
  padding-left: 10px;
  i {
    float: left;
    margin: 0;
  }
  span {
    padding-left: 10px;
  }
}

.box-mails {
  float: left;
}

.mail-user {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid $brand-color-2;
  border-radius: 2px;
  color: grey;
  float: left;
  margin: 3px 5px;
  i {
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    &:hover {
      cursor: pointer;
    }
  }
}

.invite-participants {
  width: 230px;
  text-align: right;
  margin-bottom: 5px;
  height: 25px;
  line-height: 35px;
  @media #{$small-only} {
    margin-bottom: 10px;
  }
  a {
    @extend %ppgtv-link;
  }
  i {
    position: relative;
    top: 3px;
  }
}

#users-chat {
  margin-top: 3rem;
  padding-right: 3rem;
}

.text-area {
  .attach-file {
    line-height: 20px;
    i {
      float: left;
      margin-right: 10px;
    }
    a {
      @extend %ppgtv-link;
    }
  }
}

.comments {
  float: left;
  &:last-child {
    margin-bottom: 4rem;
  }
}

.comment {
  float: left;
  margin-bottom: 1rem;
  .text {
    padding-top: 1rem;
  }
  .attached-files {
    float: left;
    border-top: 1px solid $brand-color-1;
    border-bottom: 1px solid $brand-color-2;
    padding-top: 6px;
    padding-bottom: 6px;
    p {
      font-size: 12px;
      color: grey;
      float: left;
      height: 25px;
      text-align: center;
      line-height: 30px;
      margin-right: 20px;
    }
    i {
      margin: 0 3px;
      width: 25px;
      height: 25px;
    }
  }
}

.published-time {
  color: $brand-color-2;
  font-size: 14px;
}

.user-picture {
  padding: 25px;
  padding-top: 0;
  @media #{$medium-only} {
    padding: 5px;
  }
  @media #{$small-only} {
    padding: 0;
  }
  img {
    max-width: 100%;
    border-radius: 100%;
  }
}

#project-versions {
  margin-top: 3rem;
  .heading {
    width: 100%;
    float: left;
    min-height: 30px;
    margin-bottom: 1.5rem;
    .versions-project {
      padding: 0;
      min-height: 30px;
      line-height: 30px;
      i {
        position: relative;
        top: 4px;
      }
      span {
        padding-left: 5px;
      }
      @media #{$small-only} {
        text-align: center;
      }
    }
    .add-versions {
      padding: 0;
      text-align: right;
      a {
        @extend %ppgtv-link;
      }
      i {
        position: relative;
        top: 2px;
        padding-right: 20px;
      }
      @media #{$small-only} {
        text-align: center;
      }
    }

  }
  .versions {
    width: 100%;
    float: left;
    font-size: 14px;
    .version {
      width: 100%;
      float: left;
      margin-bottom: 20px;
    }
    .version-video-thumbnail {
      width: 100%;
      height: 100px;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      margin-bottom: 10px;
    }
    .btn {
      float: left;
      width: 100%;
      padding-top: 7px;
      padding-bottom: 7px;
      background: #C0C0C0;
      color: white;
      max-width: 100%;
      &:hover {
        background: $brand-color-1;
      }
    }
  }
}

#faqs-container {
  float: left;
  width: 100%;
  border-top: 1px solid $brand-color-2;
}

#faqs {
  float: left;
  width: 100%;
}

.download-button {
  background: $brand-color-1;
  color: #fff;
  float: right;
  padding: 18px;
  padding-top: 15px;
  padding-bottom: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  transition: 0.2s ease-in-out all;
  transition-delay: 0.2s;
  width: 300px;
  &:hover {
    background: $brand-color-2;
    color: white;
  }
  @media #{$small-only} {
    float: inherit;
    display: block;
    width: 90%;
    margin: 0px auto;
    padding: 20px;
    text-align: center;
  }
  i {
    float: left;
    position: relative;
    top: -1px;
    margin-right: 10px;
  }
}

.switcher-header {
  border: 1px solid $brand-color-2;
  padding: 5px;
  cursor: pointer;
  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #767676;
  }
  i.i-close-gray {
    float: left;
    margin-right: 10px;
    position: relative;
    top: 1px;
    transform: rotate(45deg);
  }
  &:hover {
    background: rgb(230, 230, 230);
    h2 {
      color: $brand-color-2;
    }
  }
}

.answer-text {
  display: none;
}

.answer {
  margin-bottom: 15px;
  &.opened {
    .switcher-header {
      border-bottom: none;
      background: rgb(230, 230, 230);
      h2 {
        color: $brand-color-2;
      }
    }
    .answer-text {
      display: block;
      border: 1px solid $brand-color-2;
      border-top: none;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-top: 10px;
      transition: 0.2s ease-in-out all;
      transition-delay: 0.2s;
    }
  }
}
