#angular-top-slider {
  .top-slider {
    .top-slider-item {
      background: $brand-color-1;
      color: white;
      position: relative;
      overflow: hidden;
      .description {
        display: inline-block;
        z-index: 2;
        float: left;
        position: absolute;
        height: 100%;
        padding: 3rem;
        padding-left: 7rem;
        padding-right: 10rem;
        background: linear-gradient(90deg, $brand-color-1 88%,rgba(0,115,182,.02) 100%,rgba(0,115,182,.21) 130%) 0 0;
        background: -webkit-linear-gradient(0deg, $brand-color-1 88%,rgba(0,115,182,.02) 100%,rgba(0,115,182,.21) 130%) 0 0;
        background: -moz-linear-gradient( 0deg, $brand-color-1 80%, $brand-color-1, rgba(0, 115, 178, 0) 100%) 0 0;
        @media #{$medium-only} {
          padding-left: 3.5rem;
          padding-right: 4rem;
          h2 {
            font-size: 1.6rem;
          }
        }
      }
      iframe {
        margin-bottom: -3px;
      }
    }
    .top-slider-item-small {
      background: $brand-color-1;
      color: white;
      .description-small {
        padding: 2rem;
        min-height: 310px;
      }
    }
    .slick-prev {
      left: 20px;
      top: -45px;
      background: url("#{$icons-path}/i-arrow-left-white.svg") no-repeat;
    }
    .slick-next {
      right: 0;
      top: -45px;
      background: url("#{$icons-path}/i-arrow-right-white.svg") no-repeat;
    }
    .button {
      margin-top: 2rem;
      @media #{$medium-only} {
        margin-top: 1rem;
      }
      @media #{$small-only} {
        margin-top: 1rem;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
  .video-content-icons {
    position: absolute;
    bottom: 20px;
  }
}

#relatedVideos {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 300px;
  .video-thumbnail span {
    margin: 0;
  }
}

#favouriteVideos {
  .angular-video-slider {
    margin-top: 1.5rem;
    .slick-prev {
      left: 20px;
      top: -45px;
      background: url("#{$icons-path}/i-arrow-left-white.svg") no-repeat;
    }
    .slick-next {
      right: 0;
      top: -45px;
      background: url("#{$icons-path}/i-arrow-right-white.svg") no-repeat;
    }
  }
}

.slider-home {
  border-bottom: 1px solid grey;
  .slick-slide {
    outline: none !important;
    -webkit-backface-visibility: hidden !important;
    padding-right: 0 !important;
    * {
      -webkit-backface-visibility: hidden !important;
    }
  }
}

.img-slider {
  width: 100%;
  background-position: center center !important;
  background-size: cover !important;
  height: $slider-height;
}

.video-slider {
  width: 100%;
  height: $slider-height;
  margin: 0px auto;
}

.play-button {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  margin: auto;
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.btn-slider{
	width: 230px;
  z-index: 10;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid white;
}
.angular-video-slider {
  .slick-track {
    margin-left: 0.6rem;
    min-width: 100%;
  }
}

.slick-slide {
  padding-right: 1rem;
  outline: none;
}

.top-slider-cover-video {
  background: transparent;
  width: 100%;
  height:100%;
  position: absolute;
  cursor: pointer;
}
