@import 'base-styles/base';
@import 'app-styles/app';

body, html {
    overflow-x: hidden;
}

main {
  min-height: 100%;
}

.btn {
  font-family: $general-font;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 175px;
  text-align: center;
}

.btn-blue {
  @extend .btn;
  background: rgba(0, 115, 178, 0.82);
  color: white;
  &:hover{
    color: whitesmoke;
    border-color: whitesmoke;
  }
}

.btn-brand {
  @extend .btn;
  background: $brand-color-1-trans;
  color: white;
  &:hover{
    color: whitesmoke;
    border-color: whitesmoke;
  }
}

.video-list-heading-title {
  font-size: 1rem;
  color: $brand-color-4;
  padding-left: 0.9rem;
}

//Search page
.search-title {
  color: $brand-color-1;
  padding: 1.5rem;
  padding-left: 0;
  padding-bottom: 0;
}

.button {
    border: 1px solid #fff;
    background: transparent;
    font-size: 1.2rem;
    padding: .85em .5em;
}

.clear-filters {
  color: $brand-color-2;
  font-size: 14px;
  margin-bottom: 1rem;
}

.nice-select {
  padding-right: 0;
  border-radius: 0;
  height: 25px;
  line-height: 25px;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid grey;
  margin-bottom: 1rem;
  @media #{$medium-only} {
    padding-left: 0.5rem;
  }
  @media #{$small-only} {
    width: 100%;
  }
  .list {
    border-radius: 0;
    width: 100%;
    margin-top: 1px;
    border: 1px solid grey;
    border-top: none;
    box-shadow: none;
    @media #{$small-only} {
      width: 100%;
    }
  }
  .option {
    padding-right: 0;
    min-height: 25px;
    line-height: 25px;
    @media #{$medium-only} {
      padding-left: 0.5rem;
    }
    &:hover {
      background: #9e9c9c;
      color: white;
    }
  }
  &:after {
    border-bottom: 2px solid $brand-color-1;
    border-right: 2px solid $brand-color-1;
  }
}

.nice-select .option.selected {
  &:hover {
    background: #9e9c9c;
    color: white;
  }
}

.uploaded-files {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid #c2c2c2;
  border-bottom: 2px solid #c2c2c2;
}



.reveal {
  padding: 30px;
  background: #f5f5f5;
  .reveal-title {
    text-transform: uppercase;
    h3 {
      color: $brand-color-2;
      font-size: 25px;
    }
  }
  img.reveal-logo {
    width: 80px;
    margin-bottom: 2rem;
  }
  input {
    border: none;
    box-shadow: none;
  }
  .reveal-login-form {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

.thin-btn {
  padding-top: 7px;
  padding-bottom: 12px;
}

.addUserButton {
  width: 20%;
  margin-top: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background: #c4c4c4;
  background-image: -webkit-linear-gradient(top, #c4c4c4, #7d7d7d);
  background-image: -moz-linear-gradient(top, #c4c4c4, #7d7d7d);
  background-image: -ms-linear-gradient(top, #c4c4c4, #7d7d7d);
  background-image: -o-linear-gradient(top, #c4c4c4, #7d7d7d);
  background-image: linear-gradient(to bottom, #c4c4c4, #929292);
  -webkit-border-radius: 12;
  -moz-border-radius: 12;
  border-radius: 5px;
  color: #000000;
}

.social-media-tip {
  width: 50%;
  position: absolute;
  bottom: 60px;
  text-align: center;
  left: 5%;
  min-height: 60px;
  background: rgba(255, 255, 255, 0.25);
  padding: 15px;
  border-radius: 2px;
  .tip-close {
    position: absolute;
    right: 5px;
    color: white;
    top: 2px;
  }
  .social-media-button {
    display: inline-block;
  }
  .googlePlus-button {
    position: relative;
    top: 3px;
    width: 60px;
  }
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 0.25);
    position: absolute;
    left: 40%;
    right: 0;
    bottom: -10px;
    transform: rotate(180deg);
  }
}
