$icon-colors: ('', '-blue', '-white', '-gray');

$icons-path: "../icons";

%small-icon {
  width: 20px;
  height: 20px;
}

%centered-svg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain !important;
}

.arrows-slider {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 10;
  top:0;
  bottom:0;
  margin: auto;
}

.slick-next {
  background: url("#{$icons-path}/i-arrow-right.svg") no-repeat;
  @extend %centered-svg;
  text-indent: -999999px;
  @extend .arrows-slider;
  right: 9%;
}

.slick-prev {
  background: url("#{$icons-path}/i-arrow-left.svg") no-repeat;
  @extend %centered-svg;
  text-indent: -999999px;
  @extend .arrows-slider;
  left: 10%;
}

@each $color in $icon-colors {
  .i-user#{$color}{
    display: inline-block;
    @extend %small-icon;
    margin-right: 10px;
    background: url("#{$icons-path}/i-user#{$color}.svg") no-repeat;
    @extend %centered-svg;
  }

  .i-world#{$color}{
    display: inline-block;
    @extend %small-icon;
    margin-right: 5px;
    background: url("#{$icons-path}/i-world#{$color}.svg") no-repeat;
    @extend %centered-svg;
  }

    .i-facebook#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-facebook#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-twitter#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-twitter#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-youtube#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-youtube#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-linkedin#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-linkedin#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-search#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-search#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-heart#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-heart#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-down-arrow#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-down-arrow#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-share#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-share#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-close#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-close#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-close#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-close#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-filter#{$color}-ppg {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-filter#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-filter#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-filter#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-document#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-document#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-audio#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-audio#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-picture#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-picture#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-image#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-picture#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-folder#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-folder#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-download-from-cloud#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-download-from-cloud#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-download-from-cloud#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-download-from-cloud#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-folder-download#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-folder-download#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

    .i-zip-download#{$color} {
    display: inline-block;
    @extend %small-icon;
    background: url("#{$icons-path}/i-zip-download#{$color}.svg") no-repeat;
    @extend %centered-svg;
    }

  .i-trash#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-trash#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-video#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-video#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-video#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-video#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-file#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-file#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-File#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-file#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-file#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-file#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-question-mark#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-question-mark#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-home#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-home#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-cancel#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-cancel#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-add-user#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-add-user#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-add-user#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-add-user#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-left-arrow#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-left-arrow#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-menu#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-menu#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .i-tag#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/i-tag#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .upload-cloud#{$color} {
  display: inline-block;
  @extend %small-icon;
  background: url("#{$icons-path}/upload-cloud#{$color}.svg") no-repeat;
  @extend %centered-svg;
  }

  .active {
    .i-heart#{$color} {
      background: url("#{$icons-path}/i-heart#{$color}-fav.svg") no-repeat;
    }
  }

}