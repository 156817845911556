#video {
  background: #F3F3F3;
  padding-bottom: 2rem;
}

#video-details {
  .video-tags {
    span {
      border: 1px solid $brand-color-2;
    }
    a {
      color: $brand-color-2;
    }
  }
  p {
    color: #777777;
  }
}

#details-related-videos {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .video-list-heading-title {
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .video-thumbnail {
    border: none;
    span {
      margin: 0;
      color: #777777;
    }
  }
  .slick-track {
    margin: 0;
  }
  .video-thumbnail-wrapper {
    width: 300px;
  }
}

#video-medias {
  .video-list-heading-title {
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .media-file {
    float: left;
    background: white;
    margin-bottom: 7px;
    width: 100%;
  }
  .file {
    background: white;
    padding: 10px;
    color: $brand-color-2;
    .file-type, .file-size {
      color: #777777;
      text-transform: uppercase;
    }
    i {
      position: relative;
      top: 3px;
      margin-right: 1rem;
    }
  }
  .file-properties{
    text-align: right;
  }
  .media-folder {
    float: left;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 7px;
    width: 100%;
    padding-bottom: 10px;
  }
  .folder-children-media{
    padding-left: 2rem;
    .medias-children {
      float: left;
      width: 100%;
      border-bottom: 1px solid grey;
    }
  }
}

.related-video-title {
  font-size: 2rem;
  color: $brand-color-2;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.related-video-subtitle {
  color: #777777;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.separator {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.media-thumbnail {
  float: right;
  width: 150px;
  height: 150px;
}
