.header {
  min-height: 70px;
  background-color: $general-background;
  border-bottom: 1px solid grey;
  -webkit-box-shadow: 0 3px 10px -5px #000;
   -moz-box-shadow: 0 3px 10px -5px #000;
        box-shadow: 0 3px 10px -5px #000;
}

.logo {
  max-height: 60px;
  width: auto;
  height: 100%;
  margin-left: 10rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  @media #{$medium-only} {
    margin-left: 0;
    margin-top: 0.5rem;
    margin-bottom: .5rem;
  }
  @media #{$small-only} {
    margin: 0px auto;
    display: block;
    margin-top: 1rem;
  }
}

.logo-brand {
  position: relative;
  top: 0;
  @media #{$medium-only} {
    top: 0;
  }
}

.login {
  padding: 25px;
  padding-bottom: 0;
  @media #{$medium-only} {
      padding: 0;
  }
  @media #{$small-only} {
    padding: 0;
    padding-top: 25px;
  }
  .logintext{
    color: grey;
    position: relative;
    top: -6px;
    font-size: 0.95rem;
  }
  i {
    position: relative;
    top: 0;
    left: 0;
  }
  .i-world {
    top: -2px;
    left: 0px;
    margin: 0;
    margin-right: 0.5rem;
  }
  .login-container {
    text-align: right;
  }
}

.login.login-brand {
  padding-top: 0;
}

.login-home {
  display: inline-block;
  margin-left: 3rem;
}

.current-region {
  display: inline-block !important;
}

.region-selector {
  display: inline-block;
  li:first-child {
    a{
      border-right: 1px solid $brand-foundation-primary-color;
    }
  }
}

#region-dropdown {
  li {
    a {
      border: none;
    }
  }
}

.language-selector {
  display: inline-block;
  position: relative;
  top: -6px;
}

.menu {
  z-index: 2;
  li {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    a {
      color: grey;
      padding: 0.8rem 1rem;
      font-size: 0.95rem;
    }
  }
  &.submenu {
    a {
      color: black;
      padding: 0.2rem 1rem;
      margin-bottom: .5rem;
      &:hover {
        color: white;
        background-color: rgb(158, 156, 156);
      }
    }
    .active {
      display: none;
    }
  }
}

.dropdown-pane {
  width: 100%;
  padding: 0;
  li {
    a {
      color: black;
      padding: .1rem .7rem;
      margin-bottom: 5px;
      display: block;
      text-align: left;
      font-size: 0.8rem;
      font-weight: 500;
      &:hover {
        color: white;
        background-color: rgb(158, 156, 156);
      }
    }
    .active {
      display: none;
    }
  }
}

.language-selector {
  .dropdown-pane {
    min-width: 100px;
  }
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
    padding: 0;
    padding-right: 0.5rem;
    padding-left: 1rem;
    margin-top: 1rem;
    position: relative;
    min-width: 100px;
    text-align: left;
    &:after {
      right: -15px;
      top: 8px;
    }
}

.is-dropdown-submenu {
  border: none;
  border-top: none;
  text-align: left;
  min-width: auto;
  box-shadow: rgb(78, 78, 78) 0px 3px 5px 0px;
}
.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  z-index: 10;
  li:first-child {
    margin-top: 1rem;
  }
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 10px;
    top: 100%;
    width: 100%;
}

.dropdown.menu>li.opens-left>.is-dropdown-submenu {
    left: auto;
    right: -20px;
    top: 100%;
    margin-top: 10px;
    li:first-child {
      margin-top: 1rem;
    }
}

.optional-text {
  min-height: 60px;
  background-color: #F4F4F4;
  margin-bottom: 1rem;
}

.principal-headline {
  display: none;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  .headline {
    color: $brand-color-3;
  }
}

.switcher {
  padding: 0.5rem;
}

.switcher-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top: 5px;
  display: block;
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    -moz-transform: rotate(90deg); /* Firefox */
      -ms-transform: rotate(90deg); /* IE 9 */
        transform: rotate(90deg);
}

.switched-button {
  -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    -moz-transform: rotate(270deg); /* Firefox */
      -ms-transform: rotate(270deg); /* IE 9 */
        transform: rotate(270deg);
}

.all-videos {
  margin: 1rem;
  padding-top: 1rem;
  a {
    color: $brand-color-3;
    &:hover {
      color: $brand-color-1;
    }
  }
}

.brand-selector {
  padding: 3rem;
  padding-bottom: 10rem;
}

.brand-circle-wrapper {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include breakpoint(medium only){
    &:nth-child(odd) {
      border-right: 1px solid grey;
      -webkit-border-image:
        -webkit-gradient(linear, 0 0, 0 100%, from(grey), to(rgba(0, 0, 0, 0))) 1 100%;
        -webkit-border-image:
        -webkit-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
      -moz-border-image:
        -moz-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
        -o-border-image:
          -o-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
            border-image:
              linear-gradient(to bottom, rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
    }
  }

  @include breakpoint(large up){
    &:not(:last-child) {
      border-right: 1px solid grey;
      -webkit-border-image:
        -webkit-gradient(linear, 0 0, 0 100%, from(grey), to(rgba(0, 0, 0, 0))) 1 100%;
        -webkit-border-image:
        -webkit-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
      -moz-border-image:
        -moz-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
        -o-border-image:
          -o-linear-gradient(rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
            border-image:
              linear-gradient(to bottom, rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0)) 1 100%;
    }
  }
}

.brand-circle{
  width: 250px;
  height: 250px;
  @media #{$medium-only} {
    width: 200px;
    height: 200px;
  }
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  background: transparent;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 150px;
  margin: 0px auto;
  border: 2px solid #C4C4C4;
  &:hover {
   opacity: 0.7;
   -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
           transition: 0.2s;
           transition-timing-function: linear;
           transition-delay: 0.2s;
  }
}

.brand-circle{

  .img-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .brand-logo {
      vertical-align: middle;
      max-width: 75%;
      height: auto;
  }
}


.social-icons-link {
  margin-right: 5px;
}

.search-login-forms {
  @media #{$small-only} {
    text-align: left;
  }
}

.form-search {
  width: 300px;
  @media #{$medium-only} {
    width: 80%;
    padding-bottom: 0;
  }
  @media #{$small-only} {
    margin-left: 0;
    width: 70%;
  }
  display: inline-block;
  padding-top: 1.5rem;
  padding-bottom: 0px;
  i {
    top: 0;
  }
}

.search-box {
  padding-right: 15px;
  input {
    height: 30px;
    margin-bottom: 0;
  }
}

.search-box-button {
  padding: 0;
  text-align: left;
  display: inline-block;
  .button {
    padding: 10px;
    padding-top: 4px;
    padding-bottom: 1px;
    padding-left: 20px;
    padding-right: 20px;
    background: $brand-color-3;
    &:hover {
      background: $brand-color-1;
    }
  }
  i {
    left: 0;
  }
  .i-search-white {
    transform: rotate(95deg);
  }
}

.brand-login {
  display: inline-block;
  top: -20px;
  position: relative;
  .i-user {
    top: 0;
  }
  .logintext {
    top: -5px;
  }
  @media #{$medium-only} {
    .i-user {
      top: 0;
    }
    .logintext {
      top: 0;
    }
  }
}