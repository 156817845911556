.reveal-modal {
    min-height: 300px;
    visibility: hidden;
    display: none;
    position: absolute;
    left: 32%;
    top: 16%;
    width: 30%;
    right: 32%;
    z-index: 99;
    height: auto;
    background-color: #fff;
    padding: 2rem;
    border: solid 1px #666;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.4);
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    top: 8.25rem;
    transition: 0.2s ease-in-out all;
    transition-delay: 0.2s;
    -webkit-transform: scale(1);
    opacity: 1;
    @media #{$medium-only} {
      width: 50%;
      left: 25%;
    }
    @media #{$small-only} {
      width: 90%;
      left: 5%;
    }
    .upload-area {
      float: left;
      margin-bottom: 2rem;
    }
    #dropZone {
      border: 6px dashed #e6e6e6;
      padding: 20px;
      float: left;
    }

    input[type="file"]{
     -webkit-appearance: none;
     text-align: left;
     -webkit-rtl-ordering:  left;
    }

    input[type="file"]::-webkit-file-upload-button {
      -webkit-appearance: push-button;
      white-space: nowrap;
      font-size: inherit;
      -webkit-user-modify: read-only;
      margin: 0px;
      padding: 10px;
      color: black;
      background: #cccccc;
      background-image: -webkit-linear-gradient(top, #cccccc, #999999);
      background-image: -moz-linear-gradient(top, #cccccc, #999999);
      background-image: -ms-linear-gradient(top, #cccccc, #999999);
      background-image: -o-linear-gradient(top, #cccccc, #999999);
      background-image: linear-gradient(to bottom, #cccccc, #999999);
      border-radius: 5px;
      cursor: pointer;
    }
}

.ng-modal .reveal-modal {
    display: block;
    visibility: visible;
}

.reveal-modal-bg {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000;
    background: rgba(0,0,0,0.45);
    z-index: 98;
    display: none;
    top: 0;
    left: 0;
}

.ng-modal .reveal-modal-bg {
    display: block;
}

.reveal-modal .close-reveal-modal {
    font-size: 1.375rem;
    line-height: 1;
    position: absolute;
    top: 0.5rem;
    right: 0.6875rem;
    color: #aaa !important;
    text-decoration: none !important;
    font-weight: bold;
    cursor: pointer;
}

.ng-modal.ng-hide-add,
.ng-modal.ng-hide-remove {
  display: block !important;
}

.ng-modal.ng-hide {
  opacity: 0;
}

.reveal-modal.ng-hide-add,
.reveal-modal.ng-hide-remove {
  display: block !important;
}

.reveal-modal.ng-hide {
  -webkit-transform: scale(0.9);
  opacity: 0;
}

.modal-title {
  float: left;
  color: $brand-color-2;
  border-bottom: 1px solid #bebebe;
  margin-bottom: 2px;
  font-size: 25px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  h3 {
    font-size: 25px;
  }
}

.modal-btn {
  display: inline-block;
  padding: 10px;
  margin-left: 20px;
  color: black;
  background: #cccccc;
  background-image: -webkit-linear-gradient(top, #cccccc, #999999);
  background-image: -moz-linear-gradient(top, #cccccc, #999999);
  background-image: -ms-linear-gradient(top, #cccccc, #999999);
  background-image: -o-linear-gradient(top, #cccccc, #999999);
  background-image: linear-gradient(to bottom, #cccccc, #999999);
  border-radius: 5px;
  min-width: 20%;
}

.modal-file-upload {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 5px;
  }
  i {
    position: relative;
    top: 3px;
  }
  i.i-close-gray {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  span {
    margin-left: 10px;
  }
}

.attach-file-button-modal {
  background: $brand-color-1;
  color: #fff !important;
  text-decoration: none !important;
  float: left;
  padding: 18px;
  margin-top: 2rem;
  padding-bottom: 6px;
  padding-top: 13px;
  margin-left: 25%;
  text-align: center;
  transition: 0.2s ease-in-out all;
  transition-delay: 0.2s;
  min-width: 50%;
  &:hover {
    background: $brand-color-2;
    color: white;
  }
  @media #{$small-only} {
    width: 100%;
    margin: 0;
    margin-top: 2rem;
    padding: 20px;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
  }
  i {
    float: left;
    position: relative;
    top: -3px;
    margin-right: 10px;
  }
}