footer {
  background: $brand-color-1;
  padding: 3rem;
  height: auto;
  float: left;
  width: 100%;
  .footer-content {
    p {
      color: white;
      font-size: 0.8rem;
    }
    a {
      color: white;
    }
  }
  .disclaimer {
    border-right: 1px solid white;
    @media #{$small-only} {
      border: none;
    }
  }
  .footer-logo {
    width: auto;
    max-width: 100%;
    max-height: 150px;
  }
}