
#filters {
  margin-top: 1.5rem;

  .filters {
    display: none;
  }
}

.filters-container {
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
  .column-left {
    padding-left: 0;
    padding-left: .5rem;
    @media #{$medium-only} {
      margin-bottom: 0.5rem;
    }
    @media #{$small-only} {
      padding-left: 1.1rem;
    }
  }
  .column-right {
    padding-right: 0;
  }
  select {
    height: 1.8rem;
    margin: 0;
    margin-bottom: 0.7rem;
    font-size: 0.8rem;
    padding: 0.3rem;
    color: grey;
  }
  .filter-text {
    text-align: right;
    padding: 0.5rem;
    padding-top: 0.2rem;
    padding-right: 0;
    color: $brand-color-1;
    font-size: 0.8rem;
    span {
      padding-left: 0.5rem;
      position: relative;
      top: -3px;
    }
    @media #{$medium-only} {
      display: none;
    }
    @media #{$small-only} {
      display: none;
    }
  }
  .filter {
    padding-left: 0.5rem;
  }
}

.no-results-app{
  padding-top: 15px;
  text-align: center;
}

.video-list-container {
  display: inline;
}

.video-container {
  padding: 0.5rem;
  float: left;
  @media #{$small-only} {
    padding: 0;
  }
}

.video-thumbnail {
    width: 100%;
    height: 200px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: relative;
    border: $video-thumbnail-border-width solid white;
    &:hover{
      border: $video-thumbnail-border-width solid $brand-color-1;
    }
    .add-to-favorite {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
      pointer-events: all;
      &:hover {
        display: block;
       }
       @media #{$small-only} {
         display: block;
       }
    }
    .toggle-preview {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      display: none;
      pointer-events: all;
      width: 35px;
      transform: rotate(0);
      transition: 0.2s;
      @media #{$small-only} {
        display: block;
      }
      i {
        width: 100%;
        height: 35px;
      }
      &:hover {
        display: block;
       }
       &.open {
         .i-down-arrow-white {
           transform: rotate(180deg);
           transition: 0.2s;
         }
       }
    }
    span {
        background: black;
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        opacity: 0.5;
    }
}

.to-video-details {
	position: relative;
	z-index: 4;
    height: 80%;
    top: 20%;
    cursor: pointer;
    cursor: hand;
}

.video-thumbnail:hover > span {
  display: block;
}

.video-thumbnail:hover > span + div {
    display: block;
}

.video-thumbnail:hover > span + div + div {
    display: block;
}

.video-thumbnail:hover > span + div + div + div {
    display: block;
}

.video-list-container.some-preview-opened {
  .video-container {
    margin-bottom: 0;
  }
}

.video-container.preview-opened {
  padding-bottom: 0;
  .video-thumbnail {
    margin-bottom: 0;
    border: $video-thumbnail-border-width solid $brand-color-1;
    span {
      margin: 0;
    }
  }
}

.video-title-container {
    min-height: 50px;
    max-height: 50px;
    width: 100%;
    padding: 0.7rem;
    padding-left: 0;
}

.video-title {
  max-width: 100%;
  text-align: justify;
  word-wrap: break-word;
}

.video-info {
  height: $details-video-height;
  background: $brand-color-1;
  background: linear-gradient(-90deg, rgba(0, 115, 178, 0.84), rgb(0, 115, 178));
  background: -webkit-linear-gradient(0deg, $brand-color-1 88%,rgba(0,115,182,.02) 100%,rgba(0,115,182,.21) 130%) 0 0;
  background: -moz-linear-gradient( 0deg, $brand-color-1 80%, $brand-color-1, rgba(0, 115, 178, 0) 100%) 0 0;
  @media #{$medium-only} {
    height: $details-video-height + 50;
  }
  @media #{$small-only} {
    height: $details-video-height + 100;
  }
}

.related-videos {
  height: $details-video-height;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  @media #{$medium-only} {
    height: $details-video-height + 50;
  }
  @media #{$small-only} {
    height: $details-video-height + 100;
  }
  color: white;
  a {
    color: white;
  }
  h4 {
    font-size: 1rem;
    padding: 1rem;
    padding-top: 0.5rem;
  }
  .slick-slide {
    padding: 0.5rem;
    .video-thumbnail {
      border: none;
    }
  }
  .arrows-slider {
    top: -60px;
    @media #{$small-only} {
      top: 0;
    }
  }
  .slick-prev{
    left: -30px;
    background: url(../icons/i-arrow-left-white.svg) no-repeat;
    @media #{$small-only} {
      left: 17px;
    }
  }
  .slick-next {
    right: -50px;
    background: url(../icons/i-arrow-right-white.svg) no-repeat;
    @media #{$small-only} {
      right: -20px;
    }
  }
}

.video-content {
  height: $details-video-height;
  padding: 1rem;
  background: $brand-color-1;
  color: white;
  z-index: 2;
  position: absolute;
  background: linear-gradient(90deg, $brand-color-1 88%, rgba(0, 115, 182, 0.02) 100%, rgba(0, 115, 182, 0.21) 130%) 0px 0px;
  background: -webkit-linear-gradient(0deg, $brand-color-1 88%, rgba(0, 115, 182, 0.02) 100%, rgba(0, 115, 182, 0.21) 130%) 0px 0px;
  background: -moz-linear-gradient( 0deg, $brand-color-1 80%, $brand-color-1, rgba(0, 115, 178, 0) 100%) 0 0;
  @media #{$medium-only} {
    height: $details-video-height + 50;
  }
  @media #{$small-only} {
    background: $brand-color-1;
    height: $details-video-height + 100;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-right: 8rem;
    @media #{$small-only} {
      padding-right: 0;
    }
  }

  p {
    padding-right: 8rem;
    margin-bottom: 1rem;
    @media #{$small-only} {
      padding-right: 0;
    }
  }

  .button {
    border: 1px white solid;
    background: transparent;
    font-size: 1.2rem;
    padding: 0.85em 0.5em;
  }
}

.video-content-icons {
  div {
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
    span {
      position: relative;
      top: -3px;
    }
  }
}

.video-image {
  padding: 0;
  z-index: 0;
}

.large-video-thumbnail {
  width: 100%;
  height: $details-video-height;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  @media #{$medium-only} {
    height: $details-video-height + 50;
  }
  @media #{$small-only} {
    height: $details-video-height + 100;
  }
}

.close-detail-video{
  position: absolute;
  cursor: pointer;
  right: 1.7rem;
  top: 1.3rem;
  i {
    width: 30px;
    height: 30px;
  }
  @media #{$small-only} {
    z-index: 2;
  }
}

.tabs-video {
  border-top: 1px solid black;
  background: $brand-color-1;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  a {
    color: white;
    padding: 5px 25px;
    margin-left: 1rem;
    margin-right: 1rem;
    @media #{$small-only} {
      margin: 0;
    }
  }
  a.active {
    border: 1px solid white;
  }
  @media #{$small-only} {
    font-size: 0.8rem;
  }
}

.video-preview {
  position: relative;
  top: -0.5rem;
  left: -7px;
  padding-right: 1px;
  @media #{$small-only} {
    top: 0px;
    left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.video-tags {
  padding-right: 8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @media #{$small-only} {
    margin-right: 0;
  }
  a {
    color: white;
  }
  span {
    border: 1px solid white;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    margin: 0.3rem;
    display: inline-block;
    &:first-child {
      margin-left: 0;
    }
  }
}

.remove-filter-text {
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
}

.remove-filter-icon {
  margin: 5px 0;
}
