//Project Page

#project-options {
  padding-top: 3rem;
  border-top: 1px solid $brand-color-2;
  @media #{$large-up} {
    padding-left: 0;
  }
}

.button.button-ppg-gray {
  background: $brand-color-1;
  color: white;
  &:hover {
    background: $brand-color-2;
  }
}

.create-project-button-container {
  .button {
    float: right;
    position: relative;
    top: -20px;
    @media #{$small-only} {
      top: auto;
    }
  }
}

.sections {
  float: left;
}

.section-title {
  width: 100%;
  float: left;
  i {
    float: left;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }
}

.section-content {
  width: 100%;
  float: left;
  padding-left: 1.5rem;
  div {
    float: left;
  }
}

.short-description {
  resize: none;
}

.inputs-titles {
  color: $brand-color-2;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  float: left;
}

#video-filters {
  padding-right: 3rem;
}

#segment, #product, #video-type {
  width: 100%;
  float: left;
  padding: 10px;
  header {
    background: #F4F4F4;
    width: 100%;
    h4 {
      color: $brand-color-1;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      padding: 5px;
      padding-left: 10px;
    }
  }
  .options {
    padding: 1rem;
    width: 100%;
    ul, li {
      width: 100%;
    }
  }
}

.project-tags {
  label {
    min-height: 30px;
    line-height: 30px;
    outline: none;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-bottom: 0;
    border-radius: 1px;
    outline: none;
  }

  input[type="checkbox"], input[type="checkbox"]:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    outline: none;
  }

  input[type="checkbox"]:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
    outline: none;
  }

  input[type="checkbox"]:checked:after {
    content: '\2714';
    font-size: 25px;
    position: absolute;
    top: -11px;
    left: 2px;
    color: $brand-color-2;
    outline: none;
  }
}

.add-tags {
  width: 80%;
  float: left;
  height: 30px;
  margin-bottom: 0;
}

.btn-add-tag {
  width: 20%;
  float: left;
  height: 30px;
  line-height: 30px;
  border-top: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  text-align: center;
  font-weight: bold;
  &:hover {
    background: #F4F4F4;
  }
}

.suggested-tags-box {
  background: #F4F4F4;
  width: 100%;
  float: left;
  padding-top: 1rem;
}

.validated-tags {
  width: 100%;
  float: left;
  background: white;
  padding-top: 1rem;
  .validated-tag {
    @extend .mail-user;
    border: 1px solid grey;
    padding-top: 3px;
    i {
      margin-left: 40px;
    }
  }
}

#video-regions {
  padding-bottom: 3rem;
}

.item-tag-region {
  padding-left: 1.6rem;
  padding-top: 1rem;
  padding-bottom: 10px;
  padding-right: 1rem;
}

.related-video-box {
  width: 100%;
  height: 400px;
  overflow: auto;
  border: 1px solid grey;
  margin-bottom: 3rem;
  input[type="checkbox"] {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  label {
    min-height: inherit;
    line-height: 20px;
  }
  .published-time {
    margin-left: 10px;
    font-size: 14px;
  }
}

.related-video-box-item {
  margin-top: 2rem;
}

.thumbnail-related-video-box {
  height: 130px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.upload-area {
  width: 100%;
  padding: 15px;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 3rem;
}

#dropZone {
  border: 10px dashed #E6E6E6;
  border-radius: 0;
  padding: 100px;
  text-align: center;
  color: #296B6A;
  width: 100%;
  font: 20px bold;
  .upload-cloud {
    display: block;
    margin: 0px auto;
    width: 80px;
    height: 80px;
    float: none;
  }
}

.upload-file {
  padding-bottom: 2rem;
  .i-file-gray {
    position: relative;
    top: -15px;
  }
  i {
    margin-right: 10px;
  }
  .upload-file-name {
    word-break: break-all;
    display: inline-block;
    max-width: 90%;
  }
  .upload-file-properties {
    text-align: right;
    .upload-file-separator {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.create-project-button-container.down-button {
  position: relative;
  min-height: 300px;
  @media #{$small-only} {
    min-height: 60px;
  }
  .button {
    position: absolute;
    top: auto;
    bottom: 30px;
    right: 0;
    line-height: 0px;
    padding: 25px;
    padding-left: 60px;
    padding-right: 40px;
    @media #{$small-only} {
      top: auto;
    }
    i {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      left: 5px;
      bottom: 0;
      margin-left: 10px;
    }
  }
}

.modal-input {
  display: inline-block;
  width: 70%;
  float: left;
}
